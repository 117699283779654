import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GalapaGoApi from '../apis/GalapaGoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, Text } from 'react-native';

const ComingSoonScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
          justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
        },
        dimensions.width
      )}
    >
      <Image
        resizeMode={'cover'}
        source={{
          uri: 'https://static.draftbit.com/images/placeholder-image.png',
        }}
        style={StyleSheet.applyWidth(
          GlobalStyles.ImageStyles(theme)['Image'],
          dimensions.width
        )}
      />
      <Text
        accessible={true}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
          }),
          dimensions.width
        )}
      >
        {'Coming Soon'}
      </Text>
      <Button
        onPress={() => {
          const handler = async () => {
            try {
              const data = (await GalapaGoApi.fetchEventsGET(Constants))?.json;
              setGlobalVariableValue({
                key: 'eventos',
                value: data,
              });
              console.log(Constants['eventos']);
              /* 'Run a Custom Function' action requires configuration: choose a custom function */
            } catch (err) {
              console.error(err);
            }
          };
          handler();
        }}
        style={StyleSheet.applyWidth(
          GlobalStyles.ButtonStyles(theme)['Button'],
          dimensions.width
        )}
        title={'Get Started'}
      />
    </ScreenContainer>
  );
};

export default withTheme(ComingSoonScreen);
