import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GalapaGoApi from '../apis/GalapaGoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  IconButton,
  Pressable,
  ScreenContainer,
  Swiper,
  SwiperItem,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Platform,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const EventosScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [hora, setHora] = React.useState(new Date());
  const [textInputValue, setTextInputValue] = React.useState('');
  const recordsList = (Variables, setGlobalVariableValue) => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.
    console.log('records', Variables.eventsRecords);
    let newReport2 = [];

    if (Variables.eventsRecords.length !== 0) {
      Variables.eventsRecords?.forEach(evento => {
        const date = new Date(evento?.fields?.startTime);
        const dateString = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
        console.log('ev.startTime', dateString);
        if (newReport2.length !== 0) {
          let notExist = true;
          newReport2.forEach(ev => {
            ev.forEach(e => {
              // const { initialDate } = e;
              const eDate = new Date(e.fields.startTime);
              const eDateString = `${eDate.getDate()}/${eDate.getMonth()}/${eDate.getFullYear()}`;
              if (eDateString === dateString) {
                ev.push(evento);
                notExist = false;
              }
            });
          });
          if (notExist) {
            newReport2.push([evento]);
          }
        } else {
          newReport2.push([evento]);
        }
        console.log('newReport2: ====>', newReport2);
      });
    }

    if (newReport2.length !== 0) {
      setGlobalVariableValue({
        key: 'eventsRecords',
        value: newReport2,
      });
    }
    console.log('records end', Variables.eventsRecords);

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
  };

  const dateToTime = date => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.
    const dateParce = new Date(date);
    console.log('startTime', dateParce.toTimeString().slice(0, 5));

    return dateParce.toTimeString().slice(0, 5);
    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
  };

  const dateToDate = date => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.
    const dateParce = new Date(date);
    console.log('startTime', dateParce.toTimeString().slice(0, 5));

    // Get today's date
    var todaysDate = new Date();

    // call setHours to take the time out of the comparison
    if (dateParce.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
      console.log('hoy');
    }

    return dateParce.toTimeString().slice(0, 5);
    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
  };

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(253, 253, 253)' },
        dimensions.width
      )}
    >
      <GalapaGoApi.FetchFetchEventsGET>
        {({ loading, error, data, refetchFetchEvents }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* mainContainer */}
              <View>
                {/* headerContainer */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: 'rgba(50, 143, 253, 0.4)',
                      borderBottomLeftRadius: 30,
                      borderBottomRightRadius: 30,
                      height: 253,
                      justifyContent: 'center',
                      position: 'relative',
                      zIndex: 1,
                    },
                    dimensions.width
                  )}
                >
                  {/* TitleContainer */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        height: 125,
                        width: 336,
                      },
                      dimensions.width
                    )}
                  >
                    {/* LaVidaEnLasIslasTxt */}
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: 'rgb(255, 255, 255)',
                            fontFamily: 'Outfit_600SemiBold',
                            fontSize: 52,
                            letterSpacing: -1.04,
                            lineHeight: 62.4,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'La Vida\nEn Las Islas'}
                    </Text>
                  </View>
                  {/* navbarSearchContainer */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* SearchInputContainer */}
                    <>
                      {!Constants['search'] ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: 'rgb(255, 255, 255)',
                              borderRadius: 30,
                              flexDirection: 'row',
                              height: 56,
                              justifyContent: 'space-between',
                              width: 281,
                            },
                            dimensions.width
                          )}
                        >
                          <>
                            {!Constants['search'] ? null : (
                              <TextInput
                                allowFontScaling={true}
                                autoCapitalize={'none'}
                                changeTextDelay={500}
                                onChangeText={newTextInputValue => {
                                  const textInputValue = newTextInputValue;
                                  try {
                                    setValue(value);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                placeholder={Constants[
                                  'eventsSearchbarPlaceholder'
                                ].toString()}
                                placeholderTextColor={theme.colors['Light']}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextInputStyles(theme)[
                                      'Text Input'
                                    ],
                                    {
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      borderRightWidth: 0,
                                      borderTopWidth: 0,
                                      fontFamily: 'Outfit_400Regular',
                                      fontSize: 20,
                                      marginLeft: 16,
                                    }
                                  ),
                                  dimensions.width
                                )}
                                value={textInputValue}
                              />
                            )}
                          </>
                          {/* IconCerrar */}
                          <>
                            {!Constants['search'] ? null : (
                              <IconButton
                                color={theme.colors['Strong']}
                                icon={'AntDesign/close'}
                                onPress={() => {
                                  try {
                                    setGlobalVariableValue({
                                      key: 'search',
                                      value: Constants['false'],
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                size={26}
                                style={StyleSheet.applyWidth(
                                  { marginRight: 22 },
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                        </View>
                      )}
                    </>
                    {/* navbarContainer */}
                    <>
                      {Constants['search'] ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: 'rgb(255, 255, 255)',
                              borderRadius: 30,
                              flexDirection: 'row',
                              height: 56,
                              justifyContent: 'center',
                              width: 281,
                              zIndex: 1,
                            },
                            dimensions.width
                          )}
                        >
                          {/* DestacadoContainerUnselected */}
                          <>
                            {Constants['eventsDestacadoSelected'] ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: 'rgba(0, 0, 0, 0)',
                                    borderRadius: 30,
                                    height: 41,
                                    justifyContent: 'center',
                                    left: 10,
                                    position: 'absolute',
                                    width: 139,
                                    zIndex: 1,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Pressable
                                  onPress={() => {
                                    try {
                                      setGlobalVariableValue({
                                        key: 'eventsDestacadoSelected',
                                        value: Constants['true'],
                                      });
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        height: 41,
                                        justifyContent: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {Platform.OS === 'android' ? null : (
                                        <Text
                                          accessible={true}
                                          allowFontScaling={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                alignSelf: 'center',
                                                fontFamily: 'Outfit_400Regular',
                                                fontSize: 21,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Destacado\n'}
                                        </Text>
                                      )}
                                    </>
                                    {/* Text 2 */}
                                    <>
                                      {!(Platform.OS === 'android') ? null : (
                                        <Text
                                          accessible={true}
                                          allowFontScaling={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                alignSelf: 'center',
                                                fontFamily: 'Outfit_400Regular',
                                                fontSize: 21,
                                                marginTop: 6,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Destacado\n'}
                                        </Text>
                                      )}
                                    </>
                                  </View>
                                </Pressable>
                              </View>
                            )}
                          </>
                          {/* DestacadoContainerSelected */}
                          <>
                            {!Constants['eventsDestacadoSelected'] ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: 'rgb(47, 136, 241)',
                                    borderRadius: 30,
                                    height: 41,
                                    left: 17,
                                    position: 'absolute',
                                    width: 139,
                                    zIndex: 2,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Pressable 2 */}
                                <Pressable
                                  style={StyleSheet.applyWidth(
                                    { borderRadius: 30 },
                                    dimensions.width
                                  )}
                                >
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        height: 41,
                                        justifyContent: 'center',
                                        width: 139,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      allowFontScaling={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            alignSelf: 'center',
                                            color: 'rgb(255, 255, 255)',
                                            fontFamily: 'Outfit_700Bold',
                                            fontSize: 21,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Destacado'}
                                    </Text>
                                  </View>
                                </Pressable>
                              </View>
                            )}
                          </>
                          {/* TodoContainerSelected */}
                          <>
                            {Constants['eventsDestacadoSelected'] ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor: 'rgb(47, 136, 241)',
                                    borderRadius: 30,
                                    height: 41,
                                    position: 'absolute',
                                    right: 17,
                                    width: 125,
                                    zIndex: 2,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Pressable 2 */}
                                <Pressable
                                  style={StyleSheet.applyWidth(
                                    { borderRadius: 30 },
                                    dimensions.width
                                  )}
                                >
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        height: 41,
                                        justifyContent: 'center',
                                        width: 139,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      allowFontScaling={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            alignSelf: 'center',
                                            color: 'rgb(255, 255, 255)',
                                            fontFamily: 'Outfit_700Bold',
                                            fontSize: 21,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Todo'}
                                    </Text>
                                  </View>
                                </Pressable>
                              </View>
                            )}
                          </>
                          {/* TodoContainerUnselected */}
                          <>
                            {!Constants['eventsDestacadoSelected'] ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: 'rgba(0, 0, 0, 0)',
                                    borderRadius: 30,
                                    height: 41,
                                    position: 'absolute',
                                    right: 17,
                                    width: 125,
                                    zIndex: 1,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Pressable
                                  onPress={() => {
                                    try {
                                      setGlobalVariableValue({
                                        key: 'eventsDestacadoSelected',
                                        value: Constants['false'],
                                      });
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        height: 41,
                                        justifyContent: 'center',
                                        position: 'relative',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      allowFontScaling={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            alignSelf: 'center',
                                            fontFamily: 'Outfit_400Regular',
                                            fontSize: 21,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Todo'}
                                    </Text>
                                  </View>
                                </Pressable>
                              </View>
                            )}
                          </>
                        </View>
                      )}
                    </>
                    {/* IconLupa */}
                    <>
                      {Constants['search'] ? null : (
                        <IconButton
                          color={theme.colors['Internal_White']}
                          icon={'AntDesign/search1'}
                          onPress={() => {
                            try {
                              setGlobalVariableValue({
                                key: 'search',
                                value: Constants['true'],
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={28}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 26 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* IconSort */}
                    <>
                      {!Constants['search'] ? null : (
                        <IconButton
                          color={theme.colors['Internal_White']}
                          icon={'MaterialCommunityIcons/sort-variant'}
                          onPress={() => {
                            try {
                              setGlobalVariableValue({
                                key: 'search',
                                value: Constants['true'],
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={28}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 26 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                </View>
                {/* semiBolaAzul */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: 'rgb(47, 136, 241)',
                      borderTopLeftRadius: 588,
                      bottom: -311,
                      height: 250,
                      left: 49,
                      overflow: 'hidden',
                      position: 'absolute',
                      right: -247,
                      top: -13,
                      width: 577,
                      zIndex: 0,
                    },
                    dimensions.width
                  )}
                />
              </View>
              <>
                {Constants['eventsDestacadoSelected'] ? null : (
                  <FlatList
                    data={fetchData?.records}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    listKey={'YDdKOOXa'}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* eventCardContainer */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'column',
                                marginTop: 25,
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  height: 30,
                                  justifyContent: 'space-between',
                                  width: 331,
                                },
                                dimensions.width
                              )}
                            >
                              {/* FechaTxt */}
                              <Text
                                accessible={true}
                                allowFontScaling={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: 'rgb(14, 49, 64)',
                                      fontFamily: 'Outfit_600SemiBold',
                                      fontSize: 24,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Hoy Día'}
                              </Text>

                              <Pressable>
                                {/* verMasText */}
                                <Text
                                  accessible={true}
                                  allowFontScaling={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        fontFamily: 'Outfit_500Medium',
                                        fontSize: 18,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Ver más'}
                                </Text>
                              </Pressable>
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginTop: 12, width: 331 },
                                dimensions.width
                              )}
                            >
                              {/* eventTime */}
                              <Text
                                accessible={true}
                                allowFontScaling={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: 'rgb(121, 121, 121)',
                                      fontFamily: 'Outfit_400Regular',
                                      letterSpacing: 0.7,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {dateToTime(listData?.fields?.startTime)}
                                {' - '}
                                {dateToTime(listData?.fields?.endTime)}
                              </Text>
                              {/* eventContainer */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    flexDirection: 'row',
                                    height: 103,
                                    marginTop: 8,
                                    width: 331,
                                  },
                                  dimensions.width
                                )}
                              >
                                <FlatList
                                  data={listData?.fields?.eventImage}
                                  keyExtractor={(listData, index) =>
                                    listData?.id ??
                                    listData?.uuid ??
                                    index.toString()
                                  }
                                  listKey={JSON.stringify(
                                    listData?.fields?.eventImage
                                  )}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  renderItem={({ item, index }) => {
                                    const listData = item;
                                    return (
                                      <>
                                        {/* eventImage */}
                                        <Image
                                          resizeMode={'cover'}
                                          source={{ uri: `${listData?.url}` }}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ImageStyles(theme)[
                                                'Image'
                                              ],
                                              {
                                                borderRadius: 15,
                                                height: 103,
                                                width: 171,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        />
                                      </>
                                    );
                                  }}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                />
                                {/* eventInfoContainer */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexWrap: 'nowrap',
                                      marginLeft: 15,
                                      width: 160,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* islaTxt */}
                                  <Text
                                    accessible={true}
                                    allowFontScaling={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          color: 'rgb(47, 136, 241)',
                                          fontFamily: 'Outfit_600SemiBold',
                                          fontSize: 11,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {' '}
                                    {(
                                      listData?.fields?.eventIslandLocation &&
                                      listData?.fields?.eventIslandLocation[0]
                                    )?.toUpperCase()}
                                  </Text>
                                  {/* eventNameTxt */}
                                  <Text
                                    accessible={true}
                                    allowFontScaling={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text'],
                                        {
                                          fontFamily: 'Outfit_600SemiBold',
                                          fontSize: 16,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.fields?.eventName}
                                  </Text>
                                  {/* eventDescriptionTxt */}
                                  <>
                                    {!listData?.fields
                                      ?.eventDescription ? null : (
                                      <Text
                                        accessible={true}
                                        allowFontScaling={true}
                                        numberOfLines={2}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            { fontFamily: 'Outfit_300Light' }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.fields?.eventDescription}
                                      </Text>
                                    )}
                                  </>
                                </View>
                              </View>
                            </View>
                          </View>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                  />
                )}
              </>
              {/* destacadoContainer */}
              <>
                {!Constants['eventsDestacadoSelected'] ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center' },
                      dimensions.width
                    )}
                  >
                    {/* destacadoSubContainer */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          paddingTop: 23,
                          width: 335,
                        },
                        dimensions.width
                      )}
                    >
                      {/* DestacadoTxt */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { fontFamily: 'Outfit_600SemiBold', fontSize: 26 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Destacado'}
                      </Text>
                      <Swiper
                        data={fetchData?.records}
                        dotActiveColor={theme.colors.primary}
                        dotColor={theme.colors.light}
                        dotsTouchable={true}
                        keyExtractor={(swiperData, index) =>
                          swiperData?.id ?? swiperData?.uuid ?? index.toString()
                        }
                        listKey={'nn1iAkJy'}
                        loop={false}
                        renderItem={({ item, index }) => {
                          const swiperData = item;
                          return (
                            <SwiperItem
                              style={StyleSheet.applyWidth(
                                { height: 165.087, width: 219.673 },
                                dimensions.width
                              )}
                            >
                              <FlatList
                                data={swiperData?.fields?.eventImage}
                                keyExtractor={(listData, index) =>
                                  listData?.id ??
                                  listData?.uuid ??
                                  index.toString()
                                }
                                listKey={JSON.stringify(
                                  swiperData?.fields?.eventImage
                                )}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listData = item;
                                  return (
                                    <Pressable>
                                      <Image
                                        resizeMode={'cover'}
                                        source={{ uri: `${listData?.url}` }}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.ImageStyles(theme)[
                                              'Image'
                                            ],
                                            {
                                              borderRadius: 30,
                                              height: 165.087,
                                              width: 219.673,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      />
                                    </Pressable>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                              />
                            </SwiperItem>
                          );
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SwiperStyles(theme)['Swiper'],
                            { height: 230, paddingTop: 14.66 }
                          ),
                          dimensions.width
                        )}
                      />
                      {/* CategoriasTxt 2 */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            { fontFamily: 'Outfit_600SemiBold', fontSize: 26 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Categorías'}
                      </Text>
                      {/* CategoriasContainer */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', justifyContent: 'center' },
                          dimensions.width
                        )}
                      >
                        {/* CategoriasSubContainer */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              paddingTop: 15,
                              width: 335,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Pressable 2 */}
                          <Pressable>
                            <Image
                              resizeMode={'cover'}
                              source={{
                                uri: 'https://static.draftbit.com/images/placeholder-image-background.png',
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageStyles(theme)['Image'],
                                  {
                                    borderRadius: 15,
                                    height: 80,
                                    left: 0,
                                    position: 'absolute',
                                    width: 160,
                                    zIndex: 0,
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  height: 80,
                                  justifyContent: 'center',
                                  width: 160,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Text 2 */}
                              <Text
                                accessible={true}
                                allowFontScaling={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: 'rgb(255, 255, 255)',
                                      fontFamily: 'Outfit_600SemiBold',
                                      fontSize: 18,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Naturaleza'}
                              </Text>
                            </View>
                          </Pressable>
                          {/* Pressable 2 */}
                          <Pressable>
                            <Image
                              resizeMode={'cover'}
                              source={{
                                uri: 'https://static.draftbit.com/images/placeholder-image-background.png',
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageStyles(theme)['Image'],
                                  {
                                    borderRadius: 15,
                                    height: 80,
                                    left: 0,
                                    position: 'absolute',
                                    width: 160,
                                    zIndex: 0,
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  height: 80,
                                  justifyContent: 'center',
                                  width: 160,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Text 2 */}
                              <Text
                                accessible={true}
                                allowFontScaling={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: 'rgb(255, 255, 255)',
                                      fontFamily: 'Outfit_600SemiBold',
                                      fontSize: 18,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Gastronomía'}
                              </Text>
                            </View>
                          </Pressable>
                        </View>
                        {/* CategoriasSubContainer 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              paddingTop: 15,
                              width: 335,
                            },
                            dimensions.width
                          )}
                        >
                          <Pressable>
                            <Image
                              resizeMode={'cover'}
                              source={{
                                uri: 'https://static.draftbit.com/images/placeholder-image-background.png',
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageStyles(theme)['Image'],
                                  {
                                    borderRadius: 15,
                                    height: 80,
                                    left: 0,
                                    position: 'absolute',
                                    width: 160,
                                    zIndex: 0,
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  height: 80,
                                  justifyContent: 'center',
                                  width: 160,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Text 2 */}
                              <Text
                                accessible={true}
                                allowFontScaling={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: 'rgb(255, 255, 255)',
                                      fontFamily: 'Outfit_600SemiBold',
                                      fontSize: 18,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Vida Nocturna'}
                              </Text>
                            </View>
                          </Pressable>
                          {/* Pressable 3 */}
                          <Pressable>
                            <Image
                              resizeMode={'cover'}
                              source={{
                                uri: 'https://static.draftbit.com/images/placeholder-image-background.png',
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageStyles(theme)['Image'],
                                  {
                                    borderRadius: 15,
                                    height: 80,
                                    left: 0,
                                    position: 'absolute',
                                    width: 160,
                                    zIndex: 0,
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  height: 80,
                                  justifyContent: 'center',
                                  width: 160,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Text 2 */}
                              <Text
                                accessible={true}
                                allowFontScaling={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      color: 'rgb(255, 255, 255)',
                                      fontFamily: 'Outfit_600SemiBold',
                                      fontSize: 18,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Arte & Cultura'}
                              </Text>
                            </View>
                          </Pressable>
                        </View>
                      </View>
                    </View>
                  </View>
                )}
              </>
            </>
          );
        }}
      </GalapaGoApi.FetchFetchEventsGET>
    </ScreenContainer>
  );
};

export default withTheme(EventosScreen);
