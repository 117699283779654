import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const fetchEventsGET = (Constants, _args, handlers = {}) =>
  fetch(`https://api.airtable.com/v0/appUPt54kFV1JsYfT/tblqkq5UMENrV5Ff1`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['Authorization_Header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useFetchEventsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Events', args],
    () => fetchEventsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchFetchEventsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useFetchEventsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchFetchEvents: refetch });
};

export const fetchEvents2GET = (Constants, _args, handlers = {}) =>
  fetch(`https://api.airtable.com/v0/appUPt54kFV1JsYfT/tblqkq5UMENrV5Ff1`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['Authorization_Header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useFetchEvents2GET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Events', args],
    () => fetchEvents2GET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchFetchEvents2GET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useFetchEvents2GET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchFetchEvents2: refetch });
};

export const fetchUsersGET = (Constants, _args, handlers = {}) =>
  fetch(`https://api.airtable.com/v0/appUPt54kFV1JsYfT/tblL2ItYss3Z8e6Wy`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['Authorization_Header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useFetchUsersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Users', args],
    () => fetchUsersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchFetchUsersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useFetchUsersGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchFetchUsers: refetch });
};
