import { systemWeights } from 'react-native-typography';
export default {
  disabledOpacity: 0.5,
  roundness: 6,
  colors: {
    Background: 'rgba(251, 252, 253, 1)',
    Blue: 'rgb(14, 165, 233)',
    Community_Border: 'rgb(238, 242, 246)',
    Community_Bright_Yellow: 'rgb(255, 255, 0)',
    Community_Cream: 'rgb(253, 243, 215)',
    Community_Dark_Red: 'rgb(235, 58, 74)',
    Community_Dark_UI: 'rgb(23, 24, 26)',
    Community_Divider: 'rgb(215, 217, 218)',
    Community_Granite_Gray: 'rgb(221, 228, 236)',
    Community_Green_BG: 'rgb(222, 247, 229)',
    Community_Heather_Gray: 'rgb(207, 207, 207)',
    Community_Highlight_Blue: 'rgb(51, 111, 246)',
    Community_Icon_BG_Color: 'rgb(244, 246, 249)',
    Community_Icon_Fill: 'rgb(135, 140, 144)',
    Community_Light_Black: 'rgb(153, 153, 153)',
    Community_Light_Green: 'rgb(99, 196, 115)',
    Community_Light_White: 'rgb(219, 230, 253)',
    Community_List_Divider_for_Gray_List: 'rgb(233, 233, 233)',
    Community_Medium_Black: 'rgb(102, 102, 102)',
    Community_Modal_Opacity_Clear: 'rgba(0, 0, 0, 0.1)',
    Community_Modal_Opacity_Dark: 'rgba(0, 0, 0, 0.9)',
    Community_Modal_Opacity_Overlay: 'rgba(0, 0, 0, 0.7)',
    Community_Primary: 'rgb(35, 197, 98)',
    Community_Primary_Alt: 'rgb(24, 103, 255)',
    Community_Red_BG: 'rgb(249, 218, 224)',
    Community_Secondary: 'rgb(255, 24, 67)',
    Community_Secondary_Alt: 'rgb(255, 184, 0)',
    Community_Slight_White: 'rgb(239, 239, 239)',
    Community_Stone_Gray: 'rgb(223, 223, 223)',
    Community_Tertiary_Green: 'rgb(106, 197, 98)',
    Community_Transparent: 'rgba(0, 0, 0, 0)',
    Community_True_Option: 'rgb(60, 63, 66)',
    Community_White: 'rgb(255, 255, 255)',
    Community_Yellow: 'rgb(245, 186, 64)',
    'Custom #0000ff': 'rgb(0, 0, 255)',
    'Custom #101010': 'rgb(16, 16, 16)',
    'Custom #222222': 'rgb(34, 34, 34)',
    'Custom #333333': 'rgb(51, 51, 51)',
    'Custom #336ff6': 'rgb(51, 111, 246)',
    'Custom #6174f6': 'rgb(97, 116, 246)',
    'Custom #63c473': 'rgb(99, 196, 115)',
    'Custom #666666': 'rgb(102, 102, 102)',
    'Custom #6ac562': 'rgb(106, 197, 98)',
    'Custom #999999': 'rgb(153, 153, 153)',
    'Custom #a0abf9': 'rgb(160, 171, 249)',
    'Custom #ccd1fa': 'rgb(204, 209, 250)',
    'Custom #cfcfcf': 'rgb(207, 207, 207)',
    'Custom #d7d9da': 'rgb(215, 217, 218)',
    'Custom #dadada': 'rgb(218, 218, 218)',
    'Custom #dbe6fd': 'rgb(219, 230, 253)',
    'Custom #dddddd': 'rgb(221, 221, 221)',
    'Custom #dde4ec': 'rgb(221, 228, 236)',
    'Custom #def7e5': 'rgb(222, 247, 229)',
    'Custom #dfdfdf': 'rgb(223, 223, 223)',
    'Custom #e9e9e9': 'rgb(233, 233, 233)',
    'Custom #e9ecfc': 'rgb(233, 236, 252)',
    'Custom #eb3a4a': 'rgb(235, 58, 74)',
    'Custom #efefef': 'rgb(239, 239, 239)',
    'Custom #f1f3f6': 'rgb(241, 243, 246)',
    'Custom #f4f6f9': 'rgb(244, 246, 249)',
    'Custom #f5ba40': 'rgb(245, 186, 64)',
    'Custom #f5f7f8': 'rgb(245, 247, 248)',
    'Custom #f9dae0': 'rgb(249, 218, 224)',
    'Custom #fcfccf': 'rgb(252, 252, 207)',
    'Custom #fcfcfc': 'rgb(252, 252, 252)',
    'Custom #fdf3d7': 'rgb(253, 243, 215)',
    'Custom #fdfdfd': 'rgb(253, 253, 253)',
    'Custom #ff0000': 'rgb(255, 0, 0)',
    'Custom #ff5f00': 'rgb(255, 95, 0)',
    'Custom #ffa500': 'rgb(255, 165, 0)',
    'Custom #ffff00': 'rgb(255, 255, 0)',
    'Custom #ffffff': 'rgb(255, 255, 255)',
    DarkSurface: 'rgb(30, 34, 75)',
    Divider: 'rgba(234, 237, 242, 1)',
    Error: 'rgba(255, 69, 100, 1)',
    Internal_Background: 'rgb(160, 171, 249)',
    Internal_Barely_White: 'rgb(253, 253, 253)',
    Internal_Blonde_White: 'rgb(223, 223, 223)',
    Internal_Blue: 'rgb(0, 0, 255)',
    Internal_Border: 'rgb(218, 218, 218)',
    Internal_Bronzed_Gray: 'rgb(153, 153, 153)',
    Internal_Egg_White: 'rgb(252, 252, 252)',
    Internal_Ever_Blue_White: 'rgb(233, 236, 252)',
    Internal_Hot_Red: 'rgb(255, 0, 0)',
    Internal_Light_Background: 'rgb(241, 243, 246)',
    Internal_Light_Gray: 'rgb(207, 207, 207)',
    Internal_Light_Icon_BG: 'rgb(204, 209, 250)',
    Internal_Light_Stone: 'rgb(221, 221, 221)',
    Internal_Light_White: 'rgb(239, 239, 239)',
    Internal_Orange_Secondary_Alt: 'rgb(255, 95, 0)',
    Internal_Primary_Bold: 'rgb(16, 16, 16)',
    Internal_Primary_Color: 'rgb(97, 116, 246)',
    Internal_Primary_Dark: 'rgb(51, 51, 51)',
    Internal_Secondary_Dark: 'rgb(102, 102, 102)',
    Internal_Soft_Snow_White: 'rgb(245, 247, 248)',
    Internal_UI_Dark: 'rgb(34, 34, 34)',
    Internal_White: 'rgb(255, 255, 255)',
    Internal_Yellow: 'rgb(252, 252, 207)',
    Internal_Yellow_Secondary: 'rgb(255, 165, 0)',
    Light: 'rgba(165, 173, 183, 1)',
    'Light Inverse': 'rgba(255, 255, 255, 0.68)',
    Light_Inverse: 'rgba(255, 255, 255, 0.68)',
    Medium: 'rgba(70, 78, 88, 1)',
    'Medium Inverse': 'rgba(255, 255, 255, 0.87)',
    Medium_Inverse: 'rgba(255, 255, 255, 0.87)',
    Overlay: 'rgba(0, 0, 0, 0.39)',
    Overlay_Style_2: 'rgba(0, 0, 0, 0.61)',
    Primary: 'rgba(90, 69, 255, 1)',
    Secondary: 'rgba(59, 201, 234, 1)',
    Strong: 'rgba(18, 20, 44, 1)',
    'Strong Inverse': 'rgba(255, 255, 255, 1)',
    Strong_Inverse: 'rgba(255, 255, 255, 1)',
    Surface: 'rgba(255, 255, 255, 1)',
    background: 'rgba(251, 252, 253, 1)',
    blue: 'rgb(14, 165, 233)',
    communityBorder: 'rgb(238, 242, 246)',
    communityBrightYellow: 'rgb(255, 255, 0)',
    communityCream: 'rgb(253, 243, 215)',
    communityDarkRed: 'rgb(235, 58, 74)',
    communityDarkUI: 'rgb(23, 24, 26)',
    communityDivider: 'rgb(215, 217, 218)',
    communityGraniteGray: 'rgb(221, 228, 236)',
    communityGreenBG: 'rgb(222, 247, 229)',
    communityHeatherGray: 'rgb(207, 207, 207)',
    communityHighlightBlue: 'rgb(51, 111, 246)',
    communityIconBGColor: 'rgb(244, 246, 249)',
    communityIconFill: 'rgb(135, 140, 144)',
    communityLightBlack: 'rgb(153, 153, 153)',
    communityLightGreen: 'rgb(99, 196, 115)',
    communityLightWhite: 'rgb(219, 230, 253)',
    communityListDividerForGrayList: 'rgb(233, 233, 233)',
    communityMediumBlack: 'rgb(102, 102, 102)',
    communityModalOpacityClear: 'rgba(0, 0, 0, 0.1)',
    communityModalOpacityDark: 'rgba(0, 0, 0, 0.9)',
    communityModalOpacityOverlay: 'rgba(0, 0, 0, 0.7)',
    communityPrimary: 'rgb(35, 197, 98)',
    communityPrimaryAlt: 'rgb(24, 103, 255)',
    communityRedBG: 'rgb(249, 218, 224)',
    communitySecondary: 'rgb(255, 24, 67)',
    communitySecondaryAlt: 'rgb(255, 184, 0)',
    communitySlightWhite: 'rgb(239, 239, 239)',
    communityStoneGray: 'rgb(223, 223, 223)',
    communityTertiaryGreen: 'rgb(106, 197, 98)',
    communityTransparent: 'rgba(0, 0, 0, 0)',
    communityTrueOption: 'rgb(60, 63, 66)',
    communityWhite: 'rgb(255, 255, 255)',
    communityYellow: 'rgb(245, 186, 64)',
    custom_rgb0_0_255: 'rgb(0, 0, 255)',
    custom_rgb102_102_102: 'rgb(102, 102, 102)',
    custom_rgb106_197_98: 'rgb(106, 197, 98)',
    custom_rgb153_153_153: 'rgb(153, 153, 153)',
    custom_rgb160_171_249: 'rgb(160, 171, 249)',
    custom_rgb16_16_16: 'rgb(16, 16, 16)',
    custom_rgb204_209_250: 'rgb(204, 209, 250)',
    custom_rgb207_207_207: 'rgb(207, 207, 207)',
    custom_rgb215_217_218: 'rgb(215, 217, 218)',
    custom_rgb218_218_218: 'rgb(218, 218, 218)',
    custom_rgb219_230_253: 'rgb(219, 230, 253)',
    custom_rgb221_221_221: 'rgb(221, 221, 221)',
    custom_rgb221_228_236: 'rgb(221, 228, 236)',
    custom_rgb222_247_229: 'rgb(222, 247, 229)',
    custom_rgb223_223_223: 'rgb(223, 223, 223)',
    custom_rgb233_233_233: 'rgb(233, 233, 233)',
    custom_rgb233_236_252: 'rgb(233, 236, 252)',
    custom_rgb235_58_74: 'rgb(235, 58, 74)',
    custom_rgb239_239_239: 'rgb(239, 239, 239)',
    custom_rgb241_243_246: 'rgb(241, 243, 246)',
    custom_rgb244_246_249: 'rgb(244, 246, 249)',
    custom_rgb245_186_64: 'rgb(245, 186, 64)',
    custom_rgb245_247_248: 'rgb(245, 247, 248)',
    custom_rgb249_218_224: 'rgb(249, 218, 224)',
    custom_rgb252_252_207: 'rgb(252, 252, 207)',
    custom_rgb252_252_252: 'rgb(252, 252, 252)',
    custom_rgb253_243_215: 'rgb(253, 243, 215)',
    custom_rgb253_253_253: 'rgb(253, 253, 253)',
    custom_rgb255_0_0: 'rgb(255, 0, 0)',
    custom_rgb255_165_0: 'rgb(255, 165, 0)',
    custom_rgb255_255_0: 'rgb(255, 255, 0)',
    custom_rgb255_255_255: 'rgb(255, 255, 255)',
    custom_rgb255_95_0: 'rgb(255, 95, 0)',
    custom_rgb34_34_34: 'rgb(34, 34, 34)',
    custom_rgb51_111_246: 'rgb(51, 111, 246)',
    custom_rgb51_51_51: 'rgb(51, 51, 51)',
    custom_rgb97_116_246: 'rgb(97, 116, 246)',
    custom_rgb99_196_115: 'rgb(99, 196, 115)',
    darkSurface: 'rgb(30, 34, 75)',
    divider: 'rgba(234, 237, 242, 1)',
    error: 'rgba(255, 69, 100, 1)',
    internalBackground: 'rgb(160, 171, 249)',
    internalBarelyWhite: 'rgb(253, 253, 253)',
    internalBlondeWhite: 'rgb(223, 223, 223)',
    internalBlue: 'rgb(0, 0, 255)',
    internalBorder: 'rgb(218, 218, 218)',
    internalBronzedGray: 'rgb(153, 153, 153)',
    internalEggWhite: 'rgb(252, 252, 252)',
    internalEverBlueWhite: 'rgb(233, 236, 252)',
    internalHotRed: 'rgb(255, 0, 0)',
    internalLightBackground: 'rgb(241, 243, 246)',
    internalLightGray: 'rgb(207, 207, 207)',
    internalLightIconBG: 'rgb(204, 209, 250)',
    internalLightStone: 'rgb(221, 221, 221)',
    internalLightWhite: 'rgb(239, 239, 239)',
    internalOrangeSecondaryAlt: 'rgb(255, 95, 0)',
    internalPrimaryBold: 'rgb(16, 16, 16)',
    internalPrimaryColor: 'rgb(97, 116, 246)',
    internalPrimaryDark: 'rgb(51, 51, 51)',
    internalSecondaryDark: 'rgb(102, 102, 102)',
    internalSoftSnowWhite: 'rgb(245, 247, 248)',
    internalUIDark: 'rgb(34, 34, 34)',
    internalWhite: 'rgb(255, 255, 255)',
    internalYellow: 'rgb(252, 252, 207)',
    internalYellowSecondary: 'rgb(255, 165, 0)',
    light: 'rgba(165, 173, 183, 1)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    medium: 'rgba(70, 78, 88, 1)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    overlay: 'rgba(0, 0, 0, 0.39)',
    overlayStyle2: 'rgba(0, 0, 0, 0.61)',
    primary: 'rgba(90, 69, 255, 1)',
    secondary: 'rgba(59, 201, 234, 1)',
    strong: 'rgba(18, 20, 44, 1)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    surface: 'rgba(255, 255, 255, 1)',
  },
  typography: {
    body1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    body2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
    button: {
      ...systemWeights.bold,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 16,
    },
    caption: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: 16,
    },
    headline1: {
      ...systemWeights.bold,
      fontSize: 60,
      letterSpacing: 0,
      lineHeight: 71,
    },
    headline2: {
      ...systemWeights.bold,
      fontSize: 48,
      letterSpacing: 0,
      lineHeight: 58,
    },
    headline3: {
      ...systemWeights.bold,
      fontSize: 34,
      letterSpacing: 0,
      lineHeight: 40,
    },
    headline4: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: 34,
    },
    headline5: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 26,
    },
    headline6: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 24,
    },
    overline: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 2,
      lineHeight: 16,
    },
    subtitle1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    subtitle2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
  },
};
