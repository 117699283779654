import React from 'react';
import * as GalapaGoApi from '../apis/GalapaGoApi.js';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Checkbox,
  CircleImage,
  Icon,
  Link,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  ImageBackground,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const EventsWithMultipleActionsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [menuTab1, setMenuTab1] = React.useState(true);
  const [menuTab2, setMenuTab2] = React.useState(false);
  const [menuTab3, setMenuTab3] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
    >
      {/* Header Frame */}
      <View
        style={StyleSheet.applyWidth(
          { flexGrow: 0, flexShrink: 0 },
          dimensions.width
        )}
      >
        {/* Navigation Frame */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', flexGrow: 0, flexShrink: 0 },
            dimensions.width
          )}
        >
          {/* Flex Touchable */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 1, flexShrink: 0 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Left Frame */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', flexGrow: 1, flexShrink: 0 },
                  dimensions.width
                )}
              >
                {/* Screen Title Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'center',
                        color: theme.colors.strong,
                        fontFamily: 'Lato_700Bold',
                        fontSize: 24,
                        lineHeight: 24,
                        textAlign: 'left',
                      },
                      dimensions.width
                    )}
                  >
                    {'Hoy En La Isla'}
                  </Text>
                </View>
              </View>
            </Touchable>
          </View>
        </View>
      </View>
      {/* Scroll Content Frame */}
      <ScrollView
        bounces={true}
        showsVerticalScrollIndicator={true}
        style={StyleSheet.applyWidth(
          { flexGrow: 1, flexShrink: 0 },
          dimensions.width
        )}
      >
        {/* List Content Frame */}
        <View>
          <GalapaGoApi.FetchFetchEventsGET>
            {({ loading, error, data, refetchFetchEvents }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <FlatList
                  data={fetchData?.records}
                  keyExtractor={(listData, index) =>
                    listData?.id ?? listData?.uuid ?? index.toString()
                  }
                  listKey={'5ouga1MN'}
                  numColumns={1}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <>
                        {/* Records Frame */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: 12 },
                            dimensions.width
                          )}
                        >
                          {/* Flex Touchable */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                marginBottom: 12,
                                marginLeft: 12,
                                marginRight: 12,
                              },
                              dimensions.width
                            )}
                          >
                            <Touchable>
                              {/* Record Item Frame */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: theme.colors.internalBorder,
                                    borderLeftWidth: 1,
                                    borderRadius: 12,
                                    borderRightWidth: 1,
                                    borderTopWidth: 1,
                                    flexDirection: 'row',
                                    flexGrow: 0,
                                    flexShrink: 0,
                                    minWidth: 160,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Left Side Frame */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomLeftRadius: 12,
                                      borderTopLeftRadius: 12,
                                      flexGrow: 1,
                                      flexShrink: 0,
                                      minHeight: 160,
                                      minWidth: 160,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <FlatList
                                    data={listData?.fields?.eventImage}
                                    keyExtractor={(listData, index) =>
                                      listData?.id ??
                                      listData?.uuid ??
                                      index.toString()
                                    }
                                    listKey={JSON.stringify(
                                      listData?.fields?.eventImage
                                    )}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const listData = item;
                                      return (
                                        <ImageBackground
                                          resizeMode={'cover'}
                                          source={{ uri: `${listData?.url}` }}
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'flex-end',
                                              borderBottomLeftRadius: 12,
                                              borderTopLeftRadius: 12,
                                              flexGrow: 0,
                                              flexShrink: 0,
                                              height: 160,
                                              width: 160,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Badge Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                backgroundColor:
                                                  theme.colors.internalWhite,
                                                borderRadius: 12,
                                                flexGrow: 0,
                                                flexShrink: 0,
                                                justifyContent: 'center',
                                                marginRight: 12,
                                                marginTop: 12,
                                                paddingBottom: 6,
                                                paddingLeft: 6,
                                                paddingRight: 6,
                                                paddingTop: 6,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Badge Label */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors
                                                      .internalPrimaryColor,
                                                  fontFamily:
                                                    'OpenSans_500Medium',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {null}
                                            </Text>
                                          </View>
                                        </ImageBackground>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                  />
                                </View>
                                {/* Right Side Frame */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flexGrow: 1, flexShrink: 0 },
                                    dimensions.width
                                  )}
                                >
                                  {/* Top Frame */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginLeft: 12,
                                        marginRight: 12,
                                        paddingBottom: 12,
                                        paddingTop: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Nombre Evento */}
                                    <>
                                      {!listData?.fields?.eventName ? null : (
                                        <Text
                                          ellipsizeMode={'tail'}
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors.strong,
                                              fontFamily: 'OpenSans_700Bold',
                                              paddingBottom: 9,
                                              paddingTop: 9,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.fields?.eventName}
                                        </Text>
                                      )}
                                    </>
                                  </View>
                                  {/* Second Line Frame */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        flexGrow: 0,
                                        flexShrink: 0,
                                        marginLeft: 12,
                                        paddingBottom: 9,
                                        paddingTop: 9,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Flex Touchable */}
                                    <View>
                                      <Touchable>
                                        {/* Badge */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color:
                                                theme.colors
                                                  .internalPrimaryColor,
                                              fontFamily: 'OpenSans_400Regular',
                                              fontSize: 11,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.fields?.eventDescription}
                                        </Text>
                                      </Touchable>
                                    </View>
                                  </View>
                                  {/* Third Line Frame */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        marginLeft: 12,
                                        paddingBottom: 6,
                                        paddingTop: 6,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Flex Touchable */}
                                    <View>
                                      <Touchable>
                                        {/* Data Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { flexDirection: 'row' },
                                            dimensions.width
                                          )}
                                        >
                                          <CircleImage
                                            size={18}
                                            source={
                                              Images.CharlieGreen3JmfENcL24MUnsplash
                                            }
                                          />
                                          {/* Data Point */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  theme.colors
                                                    .internalSecondaryDark,
                                                fontFamily:
                                                  'OpenSans_400Regular',
                                                fontSize: 11,
                                                lineHeight: 17,
                                                marginLeft: 6,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.fields?.eventVenueName}
                                          </Text>
                                        </View>
                                      </Touchable>
                                    </View>
                                  </View>
                                  {/* Fourth Line Frame */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        marginLeft: 12,
                                        marginRight: 12,
                                        paddingBottom: 6,
                                        paddingTop: 6,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Flex Touchable */}
                                    <View>
                                      <Touchable>
                                        {/* Left Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { flexDirection: 'row' },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Icon Frame */}
                                          <View>
                                            <Icon
                                              color={
                                                theme.colors
                                                  .internalPrimaryColor
                                              }
                                              name={'Entypo/location-pin'}
                                              size={18}
                                            />
                                          </View>

                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                fontFamily:
                                                  'OpenSans_400Regular',
                                                fontSize: 10,
                                                marginLeft: 3,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.fields
                                              ?.eventIslandLocation &&
                                              listData?.fields
                                                ?.eventIslandLocation[0]}
                                          </Text>
                                        </View>
                                      </Touchable>
                                    </View>
                                    {/* Right Frame */}
                                    <View>
                                      {/* Bookmarked */}
                                      <Checkbox
                                        checkedIcon={'FontAwesome/bookmark'}
                                        color={
                                          theme.colors.internalPrimaryColor
                                        }
                                        onPress={newBookmarkedValue => {
                                          try {
                                            setCheckboxValue(
                                              newBookmarkedValue
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        size={18}
                                        status={checkboxValue}
                                        uncheckedColor={
                                          theme.colors.internalPrimaryColor
                                        }
                                        uncheckedIcon={'FontAwesome/bookmark-o'}
                                      />
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </Touchable>
                          </View>
                        </View>
                      </>
                    );
                  }}
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                />
              );
            }}
          </GalapaGoApi.FetchFetchEventsGET>
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

export default withTheme(EventsWithMultipleActionsScreen);
