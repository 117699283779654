import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Button, Pressable, ScreenContainer, withTheme } from '@draftbit/ui';
import { FlatList, Image, Text, View } from 'react-native';

const PerfilScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* mainContainer */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: 'rgb(47, 136, 241)',
            height: '100%',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* profilePic */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: 'rgb(255, 255, 255)',
              borderRadius: 50,
              height: 89.167,
              marginTop: 32,
              width: 90,
            },
            dimensions.width
          )}
        />
        {/* userName */}
        <Text
          accessible={true}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              color: 'rgb(255, 255, 255)',
              fontFamily: 'Outfit_600SemiBold',
              fontSize: 32,
              lineHeight: 38.4,
              marginTop: 13,
            }),
            dimensions.width
          )}
        >
          {'Joche Vega'}
        </Text>
        {/* navigationBar */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 27,
              width: 223,
            },
            dimensions.width
          )}
        >
          {/* PressableMiPerfilRegular */}
          <Pressable
            onPress={() => {
              console.log('PressableMiPerfilRegular ON_PRESS Start');
              let error = null;
              try {
                console.log('Start ON_PRESS:0 SET_VARIABLE');
                setGlobalVariableValue({
                  key: 'navBarPerfil',
                  value: Constants['true'],
                });
                console.log('Complete ON_PRESS:0 SET_VARIABLE');
              } catch (err) {
                console.error(err);
                error = err.message ?? err;
              }
              console.log(
                'PressableMiPerfilRegular ON_PRESS Complete',
                error ? { error } : 'no error'
              );
            }}
          >
            {/* miPerfilTextSemiBold */}
            <>
              {!Constants['navBarPerfil'] ? null : (
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: 'rgb(255, 255, 255)',
                      fontFamily: 'Outfit_600SemiBold',
                      fontSize: 20,
                    }),
                    dimensions.width
                  )}
                >
                  {'Mi Perfil'}
                </Text>
              )}
            </>
            {/* miPerfilTextRegular */}
            <>
              {Constants['navBarPerfil'] ? null : (
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: 'rgb(255, 255, 255)',
                      fontFamily: 'Outfit_400Regular',
                      fontSize: 20,
                    }),
                    dimensions.width
                  )}
                >
                  {'Mi Perfil'}
                </Text>
              )}
            </>
          </Pressable>
          {/* PressableMisNegociosBold */}
          <Pressable
            onPress={() => {
              try {
                setGlobalVariableValue({
                  key: 'navBarPerfil',
                  value: Constants['false'],
                });
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* misNegociosTextRegular */}
            <>
              {!Constants['navBarPerfil'] ? null : (
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: 'rgb(255, 255, 255)',
                      fontFamily: 'Outfit_400Regular',
                      fontSize: 20,
                    }),
                    dimensions.width
                  )}
                >
                  {'Mis Negocios'}
                </Text>
              )}
            </>
            {/* misNegociosTextSemiBold */}
            <>
              {Constants['navBarPerfil'] ? null : (
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: 'rgb(255, 255, 255)',
                      fontFamily: 'Outfit_600SemiBold',
                      fontSize: 20,
                    }),
                    dimensions.width
                  )}
                >
                  {'Mis Negocios'}
                </Text>
              )}
            </>
          </Pressable>
        </View>
        {/* misNegociosContainer */}
        <>
          {Constants['navBarPerfil'] ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: 'rgb(255, 255, 255)',
                  borderTopLeftRadius: 30,
                  borderTopRightRadius: 30,
                  height: '100%',
                  marginTop: 35,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* btnsContainer */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    height: 65,
                    justifyContent: 'space-between',
                    marginTop: 35,
                    width: 333,
                  },
                  dimensions.width
                )}
              >
                <Button
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        backgroundColor: 'rgb(74, 192, 128)',
                        borderColor: 'rgb(247, 124, 175)',
                        borderRadius: 15,
                        fontFamily: 'Outfit_600SemiBold',
                        fontSize: 16,
                        height: 65,
                        width: 159,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Crear Evento'}
                />
                {/* Button 2 */}
                <Button
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        backgroundColor: 'rgb(247, 124, 175)',
                        borderRadius: 15,
                        fontFamily: 'Outfit_600SemiBold',
                        fontSize: 16,
                        height: 65,
                        width: 159,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Registrar Local'}
                />
              </View>
              {/* TitleMisNegocios */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    fontFamily: 'Outfit_600SemiBold',
                    fontSize: 22,
                    lineHeight: 23,
                    marginTop: 41,
                  }),
                  dimensions.width
                )}
              >
                {'Mis Negocios'}
              </Text>
              {/* NegocioCard 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    height: 268,
                    width: 279,
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  source={Images.PexelsLilianSandoval135889751}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        borderRadius: 20,
                        height: 186.333,
                        marginTop: 16,
                        width: 279.5,
                      }
                    ),
                    dimensions.width
                  )}
                />
                {/* starsContainer */}
                <View
                  style={StyleSheet.applyWidth(
                    { height: 13, marginTop: 5, width: 76 },
                    dimensions.width
                  )}
                />
                {/* NegocioName */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      fontFamily: 'Outfit_600SemiBold',
                      fontSize: 18,
                      marginTop: 8,
                    }),
                    dimensions.width
                  )}
                >
                  {'Los Viches De “Don Jocy”'}
                </Text>
                {/* Isla */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: 'rgb(47, 136, 241)',
                      fontFamily: 'Outfit_600SemiBold',
                      fontSize: 12,
                      marginTop: 8,
                    }),
                    dimensions.width
                  )}
                >
                  {'SANTA CRUZ'}
                </Text>
              </View>
              <FlatList
                data={[]}
                keyExtractor={(listData, index) =>
                  listData?.id ?? listData?.uuid ?? index.toString()
                }
                listKey={'g3ClXBe5'}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item, index }) => {
                  const listData = item;
                  return (
                    <>
                      {/* NegocioCard */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            height: 268,
                            width: 279,
                          },
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'cover'}
                          source={Images.PexelsLilianSandoval135889751}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'],
                              {
                                borderRadius: 20,
                                height: 186.333,
                                width: 279.5,
                              }
                            ),
                            dimensions.width
                          )}
                        />
                        {/* starsContainer */}
                        <View
                          style={StyleSheet.applyWidth(
                            { height: 13, width: 76 },
                            dimensions.width
                          )}
                        />
                        {/* NegocioName */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Text'],
                            dimensions.width
                          )}
                        >
                          {'Los Viches De “Don Jocy”'}
                        </Text>
                        {/* Isla */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Text'],
                            dimensions.width
                          )}
                        >
                          {'SANTA CRUZ'}
                        </Text>
                      </View>
                    </>
                  );
                }}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              />
            </View>
          )}
        </>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(PerfilScreen);
