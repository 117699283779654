export default {
  Lato_700Bold: require('../assets/fonts/Lato_700Bold.ttf'),
  OpenSans_400Regular: require('../assets/fonts/OpenSans_400Regular.ttf'),
  OpenSans_500Medium: require('../assets/fonts/OpenSans_500Medium.ttf'),
  OpenSans_600SemiBold: require('../assets/fonts/OpenSans_600SemiBold.ttf'),
  OpenSans_700Bold: require('../assets/fonts/OpenSans_700Bold.ttf'),
  Outfit_400Regular: require('../assets/fonts/Outfit_400Regular.ttf'),
  Outfit_600SemiBold: require('../assets/fonts/Outfit_600SemiBold.ttf'),
  Outfit_300Light: require('../assets/fonts/Outfit_300Light.ttf'),
  Outfit_500Medium: require('../assets/fonts/Outfit_500Medium.ttf'),
  Outfit_700Bold: require('../assets/fonts/Outfit_700Bold.ttf'),
};
